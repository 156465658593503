import {
  GlobalStyles as AuroraGlobalStyles,
  darkTheme,
  defaultTheme,
} from '@ocho/aurora'
import { QueryClientProvider } from '@tanstack/react-query'
import { HelmetProvider } from 'react-helmet-async'
import { createBrowserRouter, Outlet } from 'react-router-dom'
import { StyleSheetManager } from 'styled-components'

import Activity from '@/containers/Activity/lazy'
import Application from '@/containers/Application/lazy'
import BankReconciliation from '@/containers/BankReconciliation/lazy'
import Customer from '@/containers/Customer/lazy'
import NewCustomer from '@/containers/Customer/New/lazy'
import Dashboard from '@/containers/Dashboard/lazy'
import IvansReconciliation from '@/containers/IvansReconciliation/lazy'
import Partners from '@/containers/Partners/lazy'
import Queue from '@/containers/Queue/lazy'
import ResetPassword from '@/containers/ResetPassword/lazy'
import Search from '@/containers/Search/lazy'
import Settings from '@/containers/Settings/lazy'
import SignIn from '@/containers/SignIn/lazy'
import SignUp from '@/containers/SignUp/lazy'
import Tasks from '@/containers/Tasks/lazy'
import Users from '@/containers/Users/lazy'

import AppError from '@/modules/AppError/lazy'
import Headers from '@/modules/Headers'

import PrivateRoutes from '@/components/PrivateRoutes/lazy'
import Redirect from '@/components/Redirect'

import GlobalStyles from '@/GlobalStyles'
import { isPartner } from '@/utils/constants'
import { shouldForwardProp } from '@/utils/constants/invalid-props'
import { DefaultProvider } from '@/utils/providers'
import queryClient from '@/utils/queryClient'

import { useColorScheme } from './utils/store/theme'

const errorElement = <AppError />

function Root() {
  const colorScheme = useColorScheme()

  return (
    <HelmetProvider>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <Headers />
        <GlobalStyles $theme={colorScheme} />
        <AuroraGlobalStyles
          theme={colorScheme === 'dark' ? darkTheme : defaultTheme}
        />
        <DefaultProvider>
          <QueryClientProvider client={queryClient}>
            <Outlet />
          </QueryClientProvider>
        </DefaultProvider>
      </StyleSheetManager>
    </HelmetProvider>
  )
}

const router = createBrowserRouter([
  {
    children: [
      {
        element: <SignIn />,
        errorElement,
        path: 'sign-in',
      },
      {
        element: <SignUp />,
        errorElement,
        path: 'sign-up',
      },
      {
        element: <ResetPassword />,
        errorElement,
        path: 'reset-password',
      },
      {
        children: [
          {
            element: <AppError error={{ status: 404 }} />,
            path: '*',
          },
          {
            element: <Dashboard />,
            errorElement,
            path: '',
          },
          {
            element: <Search />,
            errorElement,
            path: 'search',
          },
          {
            element: <Activity />,
            errorElement,
            path: 'activity',
          },
          {
            element: <Settings />,
            errorElement,
            path: 'settings',
          },
          {
            children: [
              {
                element: <Redirect to="/search" />,
              },
              isPartner && {
                element: <NewCustomer />,
                errorElement,
                path: 'new',
              },
              !isPartner && {
                element: <Application />,
                errorElement,
                path: ':customerId/applications/:applicationId',
              },
              {
                element: <Customer />,
                errorElement,
                path: ':customerId',
              },
              !isPartner && {
                element: <Customer />,
                errorElement,
                path: ':customerId/notes/:noteId',
              },
            ].filter(Boolean),
            path: 'customers',
          },
          !isPartner && {
            element: <IvansReconciliation />,
            errorElement,
            path: 'ivans-reconciliation',
          },
          !isPartner && {
            element: <BankReconciliation />,
            errorElement,
            path: 'bank-reconciliation',
          },
          !isPartner && {
            element: <Tasks />,
            errorElement,
            path: 'tasks',
          },
          !isPartner && {
            children: [
              {
                element: <Queue />,
                errorElement,
                index: true,
              },
              {
                element: <Queue />,
                errorElement,
                path: ':applicationId',
              },
            ],
            path: 'queue',
          },
          !isPartner && {
            children: [
              {
                element: <Users />,
                errorElement,
                index: true,
              },
              {
                element: <Users />,
                errorElement,
                path: ':userId',
              },
            ],
            path: 'users',
          },
          !isPartner && {
            children: [
              {
                element: <Partners />,
                errorElement,
                index: true,
              },
              {
                element: <Partners />,
                errorElement,
                path: ':partnerId',
              },
            ],
            path: 'partners',
          },
        ].filter(Boolean),
        element: <PrivateRoutes />,
        errorElement,
        path: '',
      },
    ],
    element: <Root />,
    errorElement,
    path: '',
  },
])

export default router
