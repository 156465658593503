/* eslint-disable max-lines */
import { TagType } from '@ocho/aurora'

export const CustomerTaskNames = [
  'DOC_CARRIER_UW',
  'DOC_OTHER',
  'OTHER',
  'DOC_FOREIGN_DRIVER_LICENSE',
  'DOC_INSURANCE_POLICY_AUTO_CHANGE_QUOTE',
  'DOC_INSURANCE_POLICY_AUTO',
  'DOC_INSURANCE_PREMIUM_FINANCE_LOAN_CONTRACT',
  'DOC_MARRIAGE_CERTIFICATE',
  'DOC_PASSPORT',
  'DOC_US_DRIVER_LICENSE',
  'DOC_US_STATE_ID',
  'DOC_VEHICLE',
]

export enum QuotesStatus {
  Success = 'SUCCESS',
}

export enum EmployeeRoles {
  InsuranceAgent = 'INSURANCE_AGENT',
  Representative = 'REPRESENTATIVE',
}

export enum InclusionStatus {
  add = 'ADD',
  exclude = 'EXCLUDE',
}

export enum EndorsementMetaData {
  Drivers = 'drivers',
  Vehicles = 'vehicles',
}

export enum InclusionStatusLocalized {
  ADD = 'included',
  EXCLUDE = 'excluded',
}

export const CustomerTaskTypes = ['DOCUMENT_SIGNATURE', 'DOCUMENT_UPLOAD']

export enum RateCall {
  final = 'RATE_CALL_FINAL',
  rc1 = 'RATE_CALL1',
  rc15 = 'RATE_CALL1_5',
}

export enum CoverageType {
  Basic = 'BASIC',
  BasicPlus = 'BASIC_PLUS',
}

export const TagTypeSeverity = {
  danger: 5,
  neutral: 1,
  primary: 3,
  secondary: 2,
  warning: 4,
}

export enum ContextType {
  Application = 'APPLICATION',
  Document = 'DOCUMENT',
  Payment = 'PAYMENT',
  Product = 'PRODUCT',
  ProductChange = 'PRODUCT_CHANGE',
  Task = 'TASK',
}

export const CommunicationLogName = {
  MANUAL: {
    APPLICATION: 'MANUAL::APPLICATION',
    OTHER: 'MANUAL::OTHER',
    PAYMENT: 'MANUAL::PAYMENT',
    POLICY: 'MANUAL::POLICY',
  },
}

export const InclusionStatusTags = {
  [InclusionStatus.add]: TagType.Secondary,
  [InclusionStatus.exclude]: TagType.Danger,
}

export enum StatusIconType {
  Danger = 'danger',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export enum CommunicationChannelType {
  Alert = 'ALERT',
  Email = 'EMAIL',
  Mail = 'MAIL',
  Sms = 'SMS',
  Voice = 'VOICE',
}

export const ProductStatusTag = {
  ACTIVE: TagType.Primary,
  CANCEL: TagType.Danger,
  INACTIVE: TagType.Danger,
  PENDING: TagType.Warning,
}

export enum ApplicationStatus {
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  DRIVER_INCIDENT_DISCOVERY = 'DRIVER_INCIDENT_DISCOVERY',
  DRIVER_VEHICLE_DISCOVERY = 'DRIVER_VEHICLE_DISCOVERY',
  EXPIRED = 'EXPIRED',
  ISSUER_QUESTIONS = 'ISSUER_QUESTIONS',
  LEAD = 'LEAD',
  LOAN_PREMIUM_FINANCE_SIGNATURE_PENDING = 'LOAN_PREMIUM_FINANCE_SIGNATURE_PENDING',
  LOAN_PREMIUM_FINANCE_SIGNED = 'LOAN_PREMIUM_FINANCE_SIGNED',
  LOAN_PREQUALIFIED_APPROVED = 'LOAN_PREQUALIFIED_APPROVED',
  LOAN_PREQUALIFIED_PENDING = 'LOAN_PREQUALIFIED_PENDING',
  NEW = 'NEW',
  POLICY_BOUND = 'POLICY_BOUND',
  POLICY_BOUND_PENDING = 'POLICY_BOUND_PENDING',
  POLICY_GENERATED = 'POLICY_GENERATED',
  POLICY_GENERATION = 'POLICY_GENERATION',
  POLICY_GENERATION_AGENT_LOCKED = 'POLICY_GENERATION_AGENT_LOCKED',
  POLICY_GENERATION_PENDING = 'POLICY_GENERATION_PENDING',
  POLICY_SIGNATURE_PENDING = 'POLICY_SIGNATURE_PENDING',
  POLICY_SIGNED = 'POLICY_SIGNED',
  PROOF_DOCUMENTATION = 'PROOF_DOCUMENTATION',
  PROOF_DOCUMENTATION_PENDING = 'PROOF_DOCUMENTATION_PENDING',
  QUOTES_FINAL = 'QUOTES_FINAL',
  QUOTES_FINAL_ACCEPTED = 'QUOTES_FINAL_ACCEPTED',
  QUOTES_FINAL_AGENT_LOCKED = 'QUOTES_FINAL_AGENT_LOCKED',
  QUOTES_FINAL_BOUNCED = 'QUOTES_FINAL_BOUNCED',
  QUOTES_FINAL_MORE_INFO_NEEDED = 'QUOTES_FINAL_MORE_INFO_NEEDED',
  QUOTES_FINAL_PENDING = 'QUOTES_FINAL_PENDING',
  QUOTES_RATE_CALL1 = 'QUOTES_RATE_CALL1',
  QUOTES_RATE_CALL1_5 = 'QUOTES_RATE_CALL1_5',
  QUOTES_RATE_CALL1_5_PENDING = 'QUOTES_RATE_CALL1_5_PENDING',
  QUOTES_RATE_CALL1_ANON = 'QUOTES_RATE_CALL1_ANON',
  QUOTES_UNPROCESSABLE = 'QUOTES_UNPROCESSABLE',
}

export enum DocumentType {
  IdDriverLicense = 'ID_DRIVER_LICENSE',
  IdMatricularId = 'ID_MATRICULAR_ID',
  IdOther = 'ID_OTHER',
  IdPassport = 'ID_PASSPORT',
  IdStateId = 'ID_STATE_ID',
  InsurancePolicyAuto = 'INSURANCE_POLICY_AUTO',
  InsurancePolicyAutoEmail = 'INSURANCE_POLICY_AUTO_EMAIL',
  InsurancePolicyAutoEmailRaw = 'INSURANCE_POLICY_AUTO_EMAIL_RAW',
  InsurancePolicyAutoEvidence = 'INSURANCE_POLICY_AUTO_EVIDENCE',
  InsurancePremiumFinanceLoanContract = 'INSURANCE_PREMIUM_FINANCE_LOAN_CONTRACT',
  Other = 'OTHER',
  PartnerEmployeeContract = 'PARTNER_EMPLOYEE_CONTRACT',
  PaymentOrderReceipt = 'PAYMENT_ORDER_RECEIPT',
  ProfilePhoto = 'PROFILE_PHOTO',
  ProofAddress = 'PROOF_ADDRESS',
  ProofPriorInsurance = 'PROOF_PRIOR_INSURANCE',
  Signature = 'SIGNATURE',
  SignatureInitial = 'SIGNATURE_INITIAL',
  VehicleCorners = 'VEHICLE_CORNERS',
  VehicleOdometer = 'VEHICLE_ODOMETER',
  VehicleVin = 'VEHICLE_VIN',
}

export enum StorageKey {
  _CypressToken = '@cypress/idToken',
  ColorScheme = 'color-scheme',
  CurrentApplicationId = '@ocho/current-application-id',
  CurrentSessionId = '@ocho/current-session-id',
  ExpandedNav = 'isExpanded',
  Language = 'i18nextLng',
  LastAppVersion = '@ocho/last-app-version',
  SelectedLocation = 'selected-location',
}

export enum Directions {
  LTR = 'ltr',
  RTL = 'rtl',
}

export enum Side {
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
}

export enum TransactionStatus {
  Error = 'error',
  Failed = 'failed',
  Pending = 'pending',
  Queued = 'queued',
  Reversed = 'reversed',
  Success = 'success',
}

export enum CustomerTaskStatus {
  agentLocked = 'AGENT_LOCKED',
  canceled = 'CANCELED',
  complete = 'COMPLETE',
  pending = 'PENDING',
  submitted = 'SUBMITTED',
}

export enum TaskStatus {
  agentLock = 'AGENT_LOCK',
  cancel = 'CANCEL',
  complete = 'COMPLETE',
  needReview = 'NEED_REVIEW',
  pending = 'PENDING',
  snooze = 'SNOOZE',
}

export enum TaskTypes {
  Cancel = 'CANCEL',
  CancelCarrier = 'CANCEL_CARRIER',
  CancelCarrierConfirmation = 'CANCEL_CARRIER_CONFIRMATION',
  Email = 'EMAIL_CARRIER',
  NotificationCarrier = 'NOTIFICATION_CARRIER',
  PaymentAdvanceFailure = 'PAYMENT_ADVANCE_FAILURE',
  PaymentProductCancel = 'PAYMENT_PRODUCT_CANCEL', // Payment after product is cancelled
  RenewalOffer = 'RENEWAL_OFFER',
}

export enum DocumentStatus {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  UNSIGNED = 'UNSIGNED',
}

export enum FiltersType {
  multiple = 'multiple',
  single = 'single',
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
export enum HttpStatus {
  BadGateway = 502,
  BadRequest = 400,
  NotFound = 404,
  Ok = 200,
  ServerError = 500,
  TooManyRequests = 429,
}
/* eslint-enable @typescript-eslint/no-magic-numbers */

export enum CarOwnership {
  Financed = 'FINANCED',
  Leased = 'LEASED',
  Owned = 'OWNED',
}

export enum DrivingRecord {
  Good = 'GOOD',
  Great = 'GREAT',
  NotGood = 'NOT_GOOD',
  Perfect = 'PERFECT',
}

export enum EndorsementTypes {
  AccidentsClaimsDiscovered = 'ACCIDENTS_CLAIMS_DISCOVERED',
  AddDriver = 'ADD_DRIVER',
  AdditionalDriversDiscovered = 'ADDITIONAL_DRIVERS_DISCOVERED',
  AddLienHolder = 'ADD_LIEN_HOLDER',
  AddVehicle = 'ADD_VEHICLE',
  ChangeAddress = 'CHANGE_ADDRESS',
  ChangeCoverage = 'CHANGE_COVERAGE',
  ChangeLienHolder = 'CHANGE_LIEN_HOLDER',
  ChangeMaritalStatus = 'CHANGE_MARITAL_STATUS',
  DeleteDriver = 'DELETE_DRIVER',
  DeleteVehicle = 'DELETE_VEHICLE',
  DriverLicenseVerification = 'DRIVER_LICENSE_VERIFICATION',
  NoProofOfAddress = 'NO_PROOF_OF_ADDRESS',
  NoProofOfPrior = 'NO_PROOF_OF_PRIOR',
  Reinstate = 'REINSTATE',
  ViolationsDiscovered = 'VIOLATIONS_DISCOVERED',
}

/*
 {@link https://en.wikipedia.org/wiki/List_of_tz_database_time_zones}
 */
export enum TimeZones {
  UsCentral = 'Us/Central',
  UsPacific = 'US/Pacific',
}

export enum Order {
  ascending = 'ascending',
  descending = 'descending',
}

export enum DriverLicenseStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Revoked = 'REVOKED',
  Suspended = 'SUSPENDED',
  Temporary = 'TEMPORARY',
}

export enum EntityStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  DISABLED = 'DISABLED',
}

// KEEP THIS ORDER FOR TABS
/* eslint-disable perfectionist/sort-enums */
export enum CustomerProductTabs {
  General = 'general',
  AmortizationSchedule = 'amortizationSchedule',
  Events = 'events',
  IvanTransactions = 'ivanTransactions',
  EarnedAmount = 'earnedAmount',
  Documents = 'documents',
}

export enum CustomerPaymentsTabs {
  Transactions = 'transactions',
  Schedules = 'schedules',
  Accounts = 'accounts',
}

export enum SessionLogsTabsValues {
  Aws = 'aws',
  Session = 'session',
}
/* eslint-enable perfectionist/sort-enums */

export enum PhoneTwilioType {
  Mobile = 'mobile',
}

export enum TypeValuesErrors {
  Number = 'The value should be a number',
}

export enum TypeValues {
  Currency = 'currency',
  Tag = 'tag',
}

export enum ProductStatus {
  Active = 'ACTIVE',
  Cancel = 'CANCEL',
  Expired = 'EXPIRED',
}

export enum ProductAccountStatus {
  Active = 'ACTIVE',
  ActiveInFuture = 'ACTIVE_IN_FUTURE',
  ActiveRenewalEligible = 'ACTIVE_RENEWAL_ELIGIBLE',
  CancellationPending = 'CANCELLATION_PENDING',
  Cancelled = 'CANCELLED',
  CancelledReinstatementEligible = 'CANCELLED_REINSTATEMENT_ELIGIBLE',
  CancelledWithBalance = 'CANCELLED_WITH_BALANCE',
  Expired = 'EXPIRED',
  ReinstatementPending = 'REINSTATEMENT_PENDING',
}

export enum ChangeRequestType {
  Cancel = 'CANCEL',
  ChangeCoverage = 'CHANGE_COVERAGE',
  ChangeLienHolder = 'CHANGE_LIEN_HOLDER',
  Reactivate = 'REACTIVATE',
}

export enum PremiumFinanceContractType {
  Full = 'FULL',
  NonFinanced = 'NON_FINANCED',
  Standard = 'STANDARD',
}

export enum LoanPaymentFrequency {
  BI_WEEKLY = 'biWeekly',
  MONTHLY = 'monthly',
  OTHER = 'other',
  SEMI_MONTHLY = 'semiMonthly',
  WEEKLY = 'weekly',
}

export enum FlagStatus {
  Critical = 'Critical',
  Stable = 'Stable',
  Warning = 'Warning',
}

export enum ProductType {
  InsuranceAuto = 'INSURANCE_AUTO',
  InsuranceRental = 'INSURANCE_RENTAL',
  InsuranceTermLife = 'INSURANCE_TERM_LIFE',
  LoanPremiumFinance = 'LOAN_PREMIUM_FINANCE',
}

export enum PaymentType {
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  Cash = 'CASH',
  Checking = 'CHECKING',
  CreditCard = 'CREDIT_CARD',
  DebitCard = 'DEBIT_CARD',
  PayNearMeOrder = 'PAY_NEAR_ME_ORDER',
  Saving = 'SAVING',
}

export enum PaymentSubType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
}

export enum IssuerName {
  Acceptance = 'ACCEPTANCE',
  AmericanAccess = 'AMERICAN_ACCESS',
  AspireGeneral = 'ASPIRE_GENERAL',
  BridgerSegundo = 'BRIDGER_SEGUNDO',
  BristolWest = 'BRISTOL_WEST',
  CommonWealthCasuality = 'COMMONWEALTH_CASUALTY',
  Covercube = 'COVERCUBE',
  DairyLand = 'DAIRYLAND',
  DairyLandB = 'DAIRYLAND_B',
  FalconGroup = 'FALCON_GROUP',
  FoundersFirstChoice = 'FOUNDERS_FIRST_CHOICE',
  FoundersMetro = 'FOUNDERS_METRO',
  Gainsco = 'GAINSCO',
  LamarGeneralAgency = 'LAMAR_GENERAL_AGENCY',
  NationalGeneral = 'NATIONAL_GENERAL',
  Progressive = 'PROGRESSIVE',
  UniqueInsuranceCompany = 'UNIQUE_INSURANCE_COMPANY',
}

export enum AttachmentContent {
  ApplicationPdf = 'application/pdf',
  Html = 'text/html; charset=utf-8',
  Image = 'image/*',
  TexPlain = 'text/plain',
}

export enum AttachmentType {
  DriverLicense = 'ID_DRIVER_LICENSE',
}

export enum PaymentsScheduleStatus {
  Aborted = 'ABORTED',
  Cancelled = 'CANCELLED',
  Error = 'ERROR',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Success = 'SUCCESS',
}

export enum StatusRateCallFinal {
  Failed = 'FAILED',
}

export enum AdmonitionType {
  Critical = 'critical',
  Info = 'info',
  Warning = 'warning',
}

export enum EndpointType {
  Email = 'EMAIL',
  Sms = 'SMS',
}

export enum IssuerQuestionTags {
  Sr22 = 'sr22',
}

export enum PaymentAccountsMethod {
  AchDebit = 'ACH_DEBIT',
  Adjustment = 'ADJUSTMENT',
  Cash = 'CASH',
  Debit = 'DEBIT',
  PayNearMe = 'PAY_NEAR_ME',
}

export enum PaymentTypeMethod {
  Manual = 'MANUAL',
  ManualAuto = 'MANUAL_AUTO',
}

export enum ConsentStatus {
  accepted = 'ACCEPTED',
  declined = 'DECLINED',
}

export enum AccountField {
  City = 'city',
  HolderName = 'holderName',
  PostalCode = 'postalCode',
  State = 'state',
  Street1 = 'street1',
  Street2 = 'street2',
}

export enum Actions {
  Delete = 'delete',
}

export enum PermissionSegments {
  Payment = 'payment',
}

export enum ProductActions {
  Cancel = 'cancel',
  CancelRequest = 'cancelRequest',
  Endorse = 'endorse',
  Reactivate = 'reactivate',
}

export enum MaritalStatus {
  Divorced = 'DIVORCED',
  DomesticPartner = 'DOMESTIC_PARTNER',
  Married = 'MARRIED',
  Separated = 'SEPARATED',
  Single = 'SINGLE',
  Unknown = 'UNKNOWN',
  Widowed = 'WIDOWED',
}

export enum DispositionDirection {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND',
}

export enum NoteType {
  Comment = 'COMMENT',
  HelpdeskTicket = 'HELPDESK_TICKET',
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND',
}

export enum DispositionKey {
  Actions = 'actions',
  AdditionalCommunications = 'additionalCommunications',
  Channels = 'channels',
  ContactReasons = 'contactReasons',
  Directions = 'directions',
  Dispositions = 'dispositions',
  SubActions = 'subActions',
  SubAdditionalCommunications = 'subAdditionalCommunications',
}

export enum ToastType {
  Error = 'error',
  Info = 'info',
  Success = 'success',
}

export enum DriversVehiclesOperationsKeys {
  InclusionStatus = 'inclusionStatus',
}

export enum DriverLicenseTypeKey {
  DriverLicense = 'DRIVER_LICENSE',
  MatriculaConsular = 'MATRICULA_CONSULAR',
  Other = 'OTHER_DRIVING_PERMIT',
}

export enum Purpose {
  DownPayment = 'DOWN_PAYMENT',
  Endorsement = 'ENDORSEMENT',
  Installment = 'INSTALLMENT',
  Other = 'OTHER',
  Reinstatement = 'REINSTATEMENT',
}

export enum ReconciliationStatus {
  Approve = 'APPROVE',
  Dispute = 'DISPUTE',
  NeedAttention = 'NEED_ATTENTION',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN',
}

export enum TransactionPurpose {
  Cancel = 'CANCEL',
  CancelNonPayment = 'CANCEL_NON_PAYMENT',
  DownPayment = 'DOWN_PAYMENT',
  Endorsement = 'ENDORSEMENT',
  Installment = 'INSTALLMENT',
  New = 'NEW',
  Other = 'OTHER',
  RefundCancellation = 'REFUND_CANCELLATION',
  RefundOverPayment = 'REFUND_OVERPAYMENT',
  Reinstatement = 'REINSTATEMENT',
  Renewal = 'RENEWAL',
  Unknown = 'UNKNOWN',
}

export enum LoanEventType {
  Cancel = 'CANCEL',
  ChargeOff = 'CHARGE_OFF',
  Create = 'CREATE',
  FeeCredit = 'FEE_CREDIT',
  FeePaymentBounced = 'FEE_PAYMENT_BOUNCED',
  PaidOff = 'PAID_OFF',
  PaycheckDateAdjustment = 'PAYCHECK_DATE_ADJUSTMENT',
  Payment = 'PAYMENT',
  PaymentAdjustment = 'PAYMENT_ADJUSTMENT',
  PaymentReversal = 'PAYMENT_REVERSAL',
  Reactivate = 'REACTIVATE',
  Rewrite = 'REWRITE',
}

export enum InsuranceStatus {
  Insured = 'Insured',
  Uninsured = 'Uninsured',
}

export enum MarketingSurveyReasonForShopping {
  GET_INSURED = 'GET_INSURED',
  NEW_CAR = 'NEW_CAR',
  OTHER = 'OTHER',
  SWITCH = 'SWITCH',
}

export enum OperativeSystems {
  Android = 'android',
  Ios = 'ios',
  Linux = 'linux',
  Macos = 'mac os',
  Windows = 'windows',
}

export enum Browsers {
  Chrome = 'chrome',
  Edge = 'edge',
  Firefox = 'firefox',
  Safari = 'safari',
}

export enum PartnerServices {
  InsuranceAutoLead = 'INSURANCE_AUTO_LEAD',
  Payment = 'PAYMENT',
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  NonBinary = 'NONBINARY',
  Unknown = 'UNKNOWN',
  Unspecified = 'UNSPECIFIED',
}

export enum TaskType {
  CancelCarrierCustomer = 'CANCEL_CARRIER_CUSTOMER',
  Manual = 'MANUAL',
}

export enum InvalidReasons {
  age = 'AGE',
  carrierUnderwriting = 'CARRIER_UNDERWRITING',
  driverCount = 'DRIVER_COUNT',
  driverPermit = 'DRIVER_PERMIT',
  other = 'OTHER',
  policyPremium = 'POLICY_PREMIUM',
  rideshare = 'RIDESHARE',
  vehicle = 'VEHICLE',
  vehicleCount = 'VEHICLE_COUNT',
  violationsPoints = 'VIOLATIONS_POINTS',
}

export enum GiveCreditReasons {
  Referral = 'REFERRAL',
}
